/**
 * Wrapper of NFT identifiers
 * 
 * e.g. SPACEROBOT-1fadf9-01
 */
export default class NFTIdentifier {
  identifier: string;
  collection: string;
  nonce: string;

  constructor(identifier: string) {
    this.identifier = identifier;
    this.collection = identifier.substring(0, identifier.lastIndexOf("-"));
    this.nonce = identifier.substring(identifier.lastIndexOf("-")+1);
  }

  getIdentifier() {
    return this.identifier;
  }

  getCollection() {
    return this.collection;
  }

  getNonce() {
    return this.nonce;
  }
}