import React from 'react';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

interface TooltipPropsType {
    text: string,
    label?: string,
}

const Tooltip = ({text, label='?'}: TooltipPropsType) => {
  const id = Buffer.from(`${label}-${text}`).toString('base64');
    return (
    <>
        <button
          data-for={id}
          data-tip={text}
          data-iscapture="true"
          type="button"
          className="btn btn-tooltip"
        >{label}</button>
      <ReactTooltip
            id={id}
            place='right'
            type='info'
            effect='solid'
            backgroundColor='rgba(0, 0, 0, 0.9)'
            className='spacerobot-tooltip'
            multiline={true}
          />
    </>);
};
export default Tooltip;