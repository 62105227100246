import React from "react";
import "./styles.scss";

interface ClaimType {
  title: string;
  children: any;
}

const Claim = ({title, children}: ClaimType) => {
  return (
    <div className="claim mb-5">
      <h2>{title}</h2>
      {children}
    </div>
  );
};

export default Claim;