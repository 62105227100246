import React from 'react';
import { mobileBreakpoint } from 'config';
import useWindowSize from 'hooks/useWindowSize';

const Desktop = ({children}: {children: React.ReactNode}) => {
    const { width } = useWindowSize();

    return (width >= mobileBreakpoint)? 
        <>{children}</>
        : <></>;
};

export default Desktop;