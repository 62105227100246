import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface RobopackPartsType {
  parts: {name: string, image: string}[];
  visible?: boolean;
  onHide?: any;
}

const Part = ({name, image}:{name:string, image:string})=>{
  return (
    <div className="col text-center">
      <img className="fade-in-image" style={{maxWidth: "275px"}} src={image} />
      <h4 className="mt-1">{name}</h4>
    </div>
  );
};

const RobopackParts = ({parts, onHide, visible}: RobopackPartsType) => {
  const [show, setShow] = useState(visible);

  function prefetchImages() {
    parts.map(part=>{
      new Image().src = part.image;
    });
  }
  useEffect(()=>{
    prefetchImages();
    setShow(visible);
  },[visible]);

  const handleClose = () => {
    setShow(false);
    if (!!onHide) onHide();
  };
  
  return (
      <Modal 
        className="robopack-parts"
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="text-center">
          <div className="container align-center"><h3 >You have unlocked</h3></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              {
                parts.map((pack, index)=><Part key={index} name={pack.name} image={pack.image} />)
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
};

export default RobopackParts;