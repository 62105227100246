import * as React from "react";
import { NFTType } from "cli/ElrondAPI";

interface StateType {
    spaceRobot?: NFTType
    roboPartCurrent?: NFTType
    roboPartsLocked?: {[key: string]: NFTType}
    roboPartsShowAll?: boolean
    robotPartsFilter?: string
    spaceRobotSelectedOnInventoryMobile?: string
}
type ActionType = 
| { type: "setSpaceRobot"; spaceRobot: NFTType; }
| { type: "setRoboPartCurrent"; robopart?: NFTType; }
| { type: "lockRoboPart"; kind: string; robopart: NFTType; } // Even "kind" is redundant as it is included in the param "robopart", I decided to use it to remark that it is allowed just ONE robopart by "kind" or type of RoboPart
| { type: "unlockRoboPart"; kind: string; }
| { type: "setRoboPartsShowAll"; value: boolean }
| { type: "setRobotPartsFilter"; filter?: string; }
| { type: "setSpaceRobotSelectedOnInventoryMobile"; spaceRobotName?: string; }
interface ContextType {
    state: StateType
    dispatch: (action: ActionType) => void
}
const Context = React.createContext<ContextType | undefined>(undefined);

function reducer(state: StateType, action: ActionType) {
    switch (action.type) {
        case "setSpaceRobot": {
            const newState = {
                ...state,
                spaceRobot: action.spaceRobot
            };
            return newState;
        }
        case "setRoboPartCurrent": {
            const newState = {
                ...state,
                roboPartCurrent: action.robopart
            };
            return newState;
        }
        case "lockRoboPart": {
            if (state.roboPartsLocked) state.roboPartsLocked[action.kind] = action.robopart;
            const newState = {
                ...state
            };
            return newState;
        }
        case "unlockRoboPart": {
            if (state.roboPartsLocked) delete state.roboPartsLocked[action.kind];

            const newState = {
                ...state
            };
            return newState;
        }
        case "setRoboPartsShowAll": {
            const newState = {
                ...state,
                roboPartsShowAll: action.value
            };
            return newState;
        }
        case "setRobotPartsFilter": {
            const newState = {
                ...state,
                robotPartsFilter: action.filter
            };
            return newState;
        }
        case "setSpaceRobotSelectedOnInventoryMobile": {
            const newState = {
                ...state,
                spaceRobotSelectedOnInventoryMobile: action.spaceRobotName
            };
            return newState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
}

export interface ContextPropsType {
    children: React.ReactNode;
    initialState: StateType;
}
function ContextProvider({ children, initialState }: ContextPropsType) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
      <Context.Provider value={{state, dispatch}}>
        {children}
      </Context.Provider>
    );
}

function useContext() {
    const context = React.useContext(Context);
    if (context === undefined) {
      throw new Error("useState must be used within a Context.Provider");
    }
    return context;
}

export {
    ContextProvider,
    useContext
};