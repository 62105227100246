import React from "react";
import './styles.scss';

interface SwitchPropTypes {
    checked: boolean,
    onChange?: (value: boolean) => void
}
const Switch = ({ checked, onChange }: SwitchPropTypes) => {
    const handleOnChange = (e: any) => {
        onChange && onChange(e.target.checked);
    };

    return (
        <div className="space-robots form-check form-switch" >
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleOnChange} checked={checked}/>
        </div>
    );
};


export default Switch;