import React from "react";

const Frame = ({children, className, style}: any) => {
  const classNames = ["frame", "container", "text-center", "p-md-3 p-lg-5"];
  classNames.push(className || "");
  return (
    <div className={classNames.join(" ")} style={style}>
      {children}
    </div>
  );
};

export default Frame;