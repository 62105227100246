import { useMemo } from "react";
import { mobileBreakpoint } from "config";
import useWindowSize from "./useWindowSize";

function useResponsive() {
    const { width } = useWindowSize();
    const isMobile = useMemo(()=> {
        return (width < mobileBreakpoint);
    }, [width]);
    const isDesktop = useMemo(()=> {
        return (width >= mobileBreakpoint);
    }, [width]);

    return {
        isMobile,
        isDesktop
    };
};

export default useResponsive;