import React, { useEffect, useState } from "react";
import Glide, { Properties as GlideProperties } from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.css";
import { RoboPackType } from "domain/types";
import { ReactComponent as NextIcon} from "../../../assets/img/next.svg"; 
import { ReactComponent as PrevIcon} from "../../../assets/img/prev.svg"; 
import "./styles.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import Separator from "../../Separator";
import Pack from "./Pack";

interface SliderPacksType {
  packs: RoboPackType[];
  onSelect: any;
  initialSelected?: number;
}

const slidesSetup: any = {
  rows: 2,
  default: 6,
  breakpoints : {
    1400: 4,
    992: 3,
    768: 2,
    576: 2
  }
};

const SliderController = () => {
  return (
    <div className="mt-5 ">
      <div data-glide-el="controls" className="container d-flex justify-content-center align-items-center">
          <a href="#" onClick={(e)=>{e.preventDefault();}} data-glide-dir="<" className="me-5">
            <PrevIcon />
          </a>
          <Separator width="240px" />
          <a href="#" onClick={(e)=>{e.preventDefault();}} data-glide-dir=">" className="ms-5">
            <NextIcon />
          </a>
      </div>
      <div data-glide-el="controls" className="container d-flex justify-content-center align-items-center mt-5 ">
        <a href="#" onClick={(e)=>{e.preventDefault();}} data-glide-dir="<<">
          <svg width="127" height="12" viewBox="0 0 127 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M126 7C126.552 7 127 6.55228 127 6C127 5.44772 126.552 5 126 5L126 7ZM0.226494 6.00001L6 11.7735L11.7735 6.00001L6 0.226508L0.226494 6.00001ZM126 5L6 5.00001L6 7.00001L126 7L126 5Z" fill="white"/>
          </svg>
        </a>
        <div className="glide_bullet" style={{width:"13px"}}/>
        <a href="#" onClick={(e)=>{e.preventDefault();}} data-glide-dir=">>">
          <svg width="127" height="12" viewBox="0 0 127 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5C0.447715 5 9.65645e-08 5.44772 0 6C-9.65645e-08 6.55228 0.447715 7 1 7L1 5ZM126.774 6.00002L121 0.226518L115.227 6.00002L121 11.7735L126.774 6.00002ZM1 7L121 7.00002L121 5.00002L1 5L1 7Z" fill="white"/>
          </svg>
        </a>
      </div>
    </div>
  );
};

const Slider = ({packs, onSelect, initialSelected}: SliderPacksType) => {
  const [selectedPack, setSelectedPack] = useState<number|undefined>(initialSelected);
  const [glide, setGlide] = useState<GlideProperties>();

  function getPerViewByBreakpoint(breakpoint: number): number {
    return slidesSetup.breakpoints[breakpoint] || slidesSetup.default;
  }

  useEffect(() => {
    setSelectedPack(initialSelected);
    const startAt = glide?.index || 0;
    glide?.destroy();
    const slider = new Glide(".glide", {
      type: "slider",
      rewind: false,
      perView: slidesSetup.default,
      startAt: startAt,
      breakpoints: {
        1400: {
          perView: getPerViewByBreakpoint(1400) 
        },
        992: {
          perView: getPerViewByBreakpoint(992)
        },
        768: {
          perView: getPerViewByBreakpoint(768)
        },
        576: {
          perView: getPerViewByBreakpoint(576)
        }
      }
    });
    const instance = slider.mount();
    setGlide(instance);
  }, [packs, selectedPack, initialSelected]);

  const onSelectPack = (index: number) => {
    const newPackIndex =(index == selectedPack)?undefined: index;
    setSelectedPack(newPackIndex);
    if (onSelect) {
      onSelect(newPackIndex);
    }
  };

  function buildSlides(rows: number) {
    const slidesLength = Math.ceil(packs.length/rows);
    const slides:JSX.Element[] = [];
    let slideIndex = 0;
    for (slideIndex = 0; slideIndex < slidesLength; slideIndex++) {
      const slideRows = (slideIndex < (slidesLength -1))? rows: (packs.length%rows || rows);
      slides.push(
        <li key={slideIndex}>
          <div className="row text-center">
            {
              Array(slideRows).fill(null).map((_, count) => {
                const packIndex = slideIndex*rows + count;
                const pack = packs[packIndex];
                return (
                  <div key={count} className={"col" + (count>0 ? " mt-3": "")}>
                    <Pack image={pack.imageSM} label={pack.label} enabled={pack.enabled} masked={packIndex!==selectedPack} onSelect={()=>onSelectPack(packIndex)}/>
                  </div>
                );
              })
            }
          </div>
        </li>
      );
    }
    return slides;
  }

  return (
    <div>
      <h3>YOUR ROBOPACKS</h3>
      <div className="glide mt-5">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {buildSlides(slidesSetup.rows)}
          </ul>
        </div>
        <SliderController />
      </div>
    </div>
  );
};

const Grid = ({packs, onSelect, initialSelected}: SliderPacksType) => {
  const [selectedPack, setSelectedPack] = useState<number|undefined>(initialSelected);
  const onSelectPack = (index: number) => {
    const newPackIndex =(index == selectedPack)?undefined: index;
    setSelectedPack(newPackIndex);
    if (onSelect) {
      onSelect(newPackIndex);
    }
  };

  useEffect(() => {
    setSelectedPack(initialSelected);
  }, [initialSelected]);

  return (
    <div className="container">
      <div className="row row-cols-auto justify-content-center">
      {Array(packs.length).fill(null).map((_, packIndex)=> {
        const pack = packs[packIndex];
        return (
            <div key={packIndex} className="col mt-3">
              <Pack image={pack.imageSM} label={pack.label} enabled={pack.enabled} masked={packIndex!==selectedPack} onSelect={()=>onSelectPack(packIndex)}/>
            </div>
          );
        })
      }
      </div>
    </div>
  );
};

function getViewPortSlides(width: number) {
  const [ breakpoint ] = Object.keys(slidesSetup.breakpoints).filter(b => Number(b)>width).sort((a,b)=> Number(a)-Number(b));
  return breakpoint? slidesSetup.breakpoints[breakpoint]: slidesSetup.default;
}

const SliderPacks = ({packs, onSelect, initialSelected}: SliderPacksType) => {
  const windowSize = useWindowSize();
  const viewPortSlides = getViewPortSlides(windowSize.width);
  if (packs.length > (slidesSetup.rows * viewPortSlides)) {
    return <Slider packs={packs} onSelect={onSelect} initialSelected={initialSelected} />;
  } else {
    return <Grid packs={packs} onSelect={onSelect} initialSelected={initialSelected}/>;
  }
};

export default SliderPacks;