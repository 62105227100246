import React, { useEffect, useMemo, useState } from "react";

interface PagerPropsType {
    initialSize: number
    index?: number
    onMove?: (index: number) => void
    children?: React.ReactNode
}
const Pager = ({index = 0, initialSize, onMove, children}: PagerPropsType) => {
    const [currentIndex, setCurrentIndex] = useState(index);
    const size = useMemo(() => {
        return initialSize;
    }, [initialSize]);

    useEffect(() => {
        setCurrentIndex(index);
    }, [index]);
    
    const moveSlide = (newIndex: number) => {
        setCurrentIndex(newIndex);
        onMove && onMove(newIndex);
    };

    const handleOnClickNext = (e: React.MouseEvent) => {
        e.preventDefault();
        moveSlide(Math.min(currentIndex + 1, size - 1));
    };

    const handleOnClickPrev = (e: React.MouseEvent) => {
        e.preventDefault();
        moveSlide(Math.max(currentIndex - 1, 0));
    };

    return (
        <div className="row">
            <div className="col d-flex align-items-center justify-content-end px-0">
                {currentIndex > 0 && <a href="#" onClick={handleOnClickPrev}><img className='navigate-icon' src='/assets/prev.svg' /></a>}
            </div>
            <div className="col">
                {children}
            </div>
            <div className="col d-flex align-items-center justify-content-start px-0">
                {currentIndex < size - 1 && <a href="#" onClick={handleOnClickNext}><img className='navigate-icon' src='/assets/next.svg' /></a>}
            </div>
        </div>
    );
};

export default Pager;