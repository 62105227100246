import React, { useEffect, useState } from 'react';
import './styles.scss';

interface TabsPropsType {
    tabs: Array<string>
    initialTab: number
    onChange?: (index: number) => void
}
const Tabs = ({tabs, initialTab, onChange}: TabsPropsType) => {
    const [currentTab, setCurrentTab] = useState(initialTab);

    const handleOnClick = (index: number) => {
        setCurrentTab(index);
        onChange && onChange(index);
    };

    // Captures the event of changing the tab from parent component  
    useEffect(() => {
        setCurrentTab(initialTab);
    }, [initialTab]);

    return (
        <div className="row">
            {
                tabs.map((tab, index) => 
                        <div key={index} className={`col ${(index===0)? "text-end": "text-start ms-5"}`}>
                            <a href="#" onClick={(e) => {e.preventDefault(); handleOnClick(index); }}><h2 className={`tab ${(index === currentTab)? "active": ""}`}>{tab}</h2></a>
                        </div>
                )
            }
        </div>
    );
};

export default Tabs;