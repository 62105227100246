import React, { useEffect, useMemo, useRef } from 'react';
import "./styles.scss";
import {  NFTType } from 'cli/ElrondAPI';
import { SpaceRobotPartName } from 'config';
import { findByFolderAndFilename, findByNonce, findHands, host, order } from 'src-parts-file-list';
import { getAttributeFromNFT, getExternalImageOfNFT, ROBOPART_ATTRIBUTE_KIND } from 'utils';

function getSpaceRobotLayers(nft: NFTType) {
    const coreLayers = ["Background"].concat(SpaceRobotPartName);
    const layers: {[key: string]: Array<any>} = {};
    coreLayers.map(part => {
        const [value] = getAttributeFromNFT<string>({nft, attributes: [part]});
        if (value) {
            /**
             * The attribute for hands in the NFT of space robots is "Hands" and the folder/path of the layers is "Hand_Right" and "Hand_Left"
             * The value of the attribute "Hands" is "HN StrikerBot A11 D12" and the filename of the layer is "HN_StrikerBot_A11_D12" and "HNL_StrikerBot_A11_D12"
             */
            const partLayers = (part == 'Hands')? findHands(value): findByFolderAndFilename(part, value);
            layers[part] = partLayers;
        }
    });

    return layers;
}

function getRoboPartsLayers(roboParts: Array<NFTType>) {
    const layers: {[key: string]: Array<any>} = {};
    roboParts.map((nft) => {
        const partLayers = findByNonce(nft.identifier);
        if (!layers) {
            console.error("Missing layer of RoboPart nonce %s", nft.identifier);
        } else {
            const [partName] = getAttributeFromNFT<string>({nft, attributes: [ROBOPART_ATTRIBUTE_KIND]});
            layers[partName] = partLayers;
        }
    });
    return layers;
}

interface RobotCompositionPropsType {
    spaceRobot: NFTType
    roboParts?: Array<NFTType>
}
const RobotComposition = ({spaceRobot, roboParts = []}: RobotCompositionPropsType) => {
    const imgLoadingRef = useRef<HTMLImageElement>(null);

    const roboPartLayers = useMemo(() => {
        if (!roboParts || roboParts.length == 0) {
            return [];
        }
        const spaceRobotLayers = getSpaceRobotLayers(spaceRobot);
        const roboPartsLayers = getRoboPartsLayers(roboParts);
        const upgradeLayers = {...spaceRobotLayers, ...roboPartsLayers};

        return Object.values(upgradeLayers).flatMap((layers) => {
            return layers.map(layer => {
                const { folder, filename } = layer;
                const layerOrder = order[folder];
                return <img key={folder+filename} className='overlayed' style={{zIndex: layerOrder}} src={`${host}/${folder}/${filename}`} />;
            });
        });
    }, [spaceRobot, roboParts]);

    useEffect(() => {
        if (imgLoadingRef.current) {
            imgLoadingRef.current.style.display = 'block';
        }
        setTimeout(() => {
            if (imgLoadingRef.current) {
                imgLoadingRef.current.style.display = 'none';
            }
        }, 1300);
    }, [spaceRobot]);

    return (
        <>
            <div className="robot-composition" style={{position: "relative", display:"inline-block"}}>
                <div className="image" >
                    {(!roboPartLayers || roboPartLayers.length == 0)?
                     <img className='overlayed' style={{zIndex: 10}} src={getExternalImageOfNFT(spaceRobot)} />
                    :(
                        <>
                            <img ref={imgLoadingRef} key='loading-image' className='overlayed' style={{zIndex: 1001, display: 'none'}} src='/assets/img-loading.jpeg' />
                            {roboPartLayers}
                        </>
                    )} 
                </div>
            </div>
        </>
    );
};

export default RobotComposition;