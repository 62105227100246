import React from "react";
import "./styles.scss";

interface PackType {
  image: string;
  label: string;
  enabled: boolean;
  masked?: boolean;
  onSelect?: any;
}
const Pack = ({image, label, enabled, masked, onSelect}: PackType) => {
  const handleOnClick=(e: React.MouseEvent)=>{
    e.preventDefault();
    if (onSelect) {
      onSelect();
    }
  };

  const body = (
    <div className="pack" style={{position:"relative", display:"inline-block"}}>
      <img src={image} />
      {masked && 
        <div className="overlay d-flex align-items-center justify-content-center">
          <h4>{label}</h4>
        </div>
      }
    </div>);

  return (
    enabled? 
    <a href="#" onClick={handleOnClick}>
      {body}
    </a>
    : <>{body}</>
  );
};

export default Pack;