import axios from "axios";
import { Transaction } from "./types";

// https://devnet-api.elrond.com/accounts/erd1n0vauz0gsxmpyru54tp0l8ywa0aylg35r3xdaz4gyesp0efdj0rsp6qgly/nfts?identifiers=SPACEROBOT-1fadf9-01
// https://devnet-api.elrond.com/accounts/erd1n0vauz0gsxmpyru54tp0l8ywa0aylg35r3xdaz4gyesp0efdj0rsp6qgly/nfts/count?identifiers=SPACEROBOT-1fadf9-01

interface FetchAccountsType {
  apiAddress: string;
  address: string;
  timeout: number;
  page?: number;
  params?: any;
}
export interface ObjectType {
  [key: string]: any;
}
export interface NFTType {
  identifier: string;
  collection?: string;
  attributes?: string;
  nonce?: string;
  type?: string;
  name?: string;
  creator?: string;
  uris?: string[];
  url?: string;
  isWhitelistedStorage?: boolean;
  thumbnailUrl?: string;
  tags?: string[];
  metadata?: ObjectType;
  balance?: string;
  royalties?: number;
  ticker?: string;
}

const fetchAccounts =
  <Type>(resource: string) =>
  async ({
    apiAddress,
    address,
    timeout,
    params,
  }: FetchAccountsType): Promise<{ data?: Type; success: boolean }> => {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}${resource}`,
        {
          params,
          timeout,
        },
      );

      return {
        data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

interface getNftsType {
  apiAddress: string;
  timeout: number;
  identifiers?: Array<string>;
}
const getNfts = async ({
  apiAddress,
  timeout,
  identifiers,
}: getNftsType): Promise<{ data?: Array<NFTType>; success: boolean }> => {
  try {
    const { data } = await axios.get(`${apiAddress}/nfts`, {
      params: {
        identifiers: identifiers?.join(","),
      },
      timeout,
    });

    return {
      data: data,
      success: data !== undefined,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

interface getAccountNftsType {
  apiAddress: string;
  address: string;
  timeout: number;
  identifiers?: string[];
  collections?: string[];
}
const getAccountNfts = async ({
  apiAddress,
  address,
  timeout,
  identifiers,
  collections,
}: getAccountNftsType): Promise<{ data?: NFTType[]; success: boolean }> => {
  /**
   * NOTE 2022-01-19 the API of Elrond returns state 500 when sending more than one token identifier
   *
   * Example:
   *
   * https://devnet-api.elrond.com/accounts/erd1n0vauz0gsxmpyru54tp0l8ywa0aylg35r3xdaz4gyesp0efdj0rsp6qgly/nfts/count?identifiers=SPACEROBOT-1fadf9-03&identifiers=SPACEROBOT-1fadf9-01
   */
  const result = await fetchAccounts<NFTType[]>("/nfts")({
    apiAddress,
    address,
    timeout,
    params: {
      identifiers: identifiers?.join(","),
      collections: collections?.join(","),
      size: 200,
    },
  });

  return result;
};

interface GetTransactionType {
  apiAddress: string;
  txHash: string;
  timeout: number;
}
async function getTransaction({
  apiAddress,
  txHash,
  timeout,
}: GetTransactionType): Promise<Transaction> {
  const url = `/transactions/${txHash}`;
  const { data } = await axios.get(`${apiAddress}${url}`, {
    params: {},
    timeout,
  });

  return data;
}

interface GetNftType {
  apiAddress: string;
  identifier: string;
  timeout: number;
}
async function getNft({
  apiAddress,
  identifier,
  timeout,
}: GetNftType): Promise<any> {
  try {
    const { data } = await axios.get(`${apiAddress}/nfts/${identifier}`, {
      params: {},
      timeout,
    });

    return {
      data: data,
      success: data !== undefined,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
}

interface GetCollectionType {
  apiAddress: string;
  collection: string;
  timeout: number;
  size?: number;
}
async function getCollection({
  apiAddress,
  collection,
  timeout,
  size,
}: GetCollectionType) {
  try {
    const { data } = await axios.get(
      `${apiAddress}/collections/${collection}/nfts`,
      {
        params: { size },
        timeout,
      },
    );

    return {
      data: data,
      success: data !== undefined,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
}

export { getAccountNfts, getTransaction, getNft, getNfts, getCollection };
