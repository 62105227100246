import React, { useRef, useCallback, useEffect } from 'react';

const ImageLoading = ({src, imgProps}: any) => {
    const imgProgressRef = useRef<HTMLImageElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    function hideLoading(hide = true) {
        if (imgProgressRef.current) {
            imgProgressRef.current.style.display = hide? 'none': 'block';
        }
        if (imgRef.current) {
            imgRef.current.style.display = hide? 'block': 'none';
        }
    }

    const onLoaded = useCallback(() => {
        hideLoading();
    }, [src]);
    
    useEffect(()=> {
        if (imgRef.current && imgRef.current.complete) {
            hideLoading();
        } else {
            hideLoading(false);
            setTimeout(()=>{hideLoading(true);}, 1200); // To avoid issues with slow speed of the hosting of images and mobile devices.
        }
    }, [src]);
    return (
        <>
            <img ref={imgProgressRef} src='/assets/img-loading.jpeg' {...imgProps}/>
            <img ref={imgRef} src={src} {...imgProps} style={{display: 'none'}} onLoad={onLoaded}/>
        </>
    );
};

export default ImageLoading;
