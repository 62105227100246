import { useState, useEffect } from "react";

interface WindowSizeType {
  width: number;
  height: number;
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSizeType>({
                                          width: window.innerWidth,
                                          height: window.innerHeight
                                      });
  useEffect(() => {
    function handleWindowSize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener("resize", handleWindowSize);

    return () => {
      window.removeEventListener("resize", handleWindowSize);
    };
  });

  return windowSize;
}