import React, { useRef, useEffect } from "react";

interface VideoType {
  video: string;
  muted?: boolean;
  onEnded?: any;
  id?: string;
  loop?: boolean;
  isUnlockingVideo?: boolean;
}
const Video = ({video, muted, onEnded, id, loop, isUnlockingVideo}: VideoType)=> {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(()=>{
    videoRef.current?.load();
    if (!!onEnded) {
      videoRef.current?.addEventListener("ended", ()=>{
        onEnded();
      });
    }
  }, [video]);
  if (isUnlockingVideo) {
    return (
      <div id={id} className="container text-center">
        <div className="container">
          <video ref={videoRef} autoPlay={true} muted={true} loop={loop} playsInline controls={true}>
            <source src={video} type="video/mp4" />
            Sorry, your browser doesn&apos;t support embedded videos.
          </video>
        </div>
      </div>
    );
  } else {
    return (
      <div id={id} className="container text-center">
        <div className="container">
          <video ref={videoRef} autoPlay={true} muted={false} loop={loop} className="video-unbox">
            <source src={video} type="video/mp4" />
            Sorry, your browser doesn&apos;t support embedded videos.
          </video>
        </div>
      </div>
    );
  }
};

export default Video;