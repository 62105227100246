import React from 'react';
import { ReactComponent as AttackIcon} from "assets/icon/attack.svg"; 
import { ReactComponent as DefenseIcon} from "assets/icon/defense.svg"; 
import "./styles.scss";

interface TraitsPropsType {
    attack: number
    defense: number
    classNames?: Array<string>
}
const Traits = ({attack, defense, classNames}: TraitsPropsType) => {
    const className = `traits d-flex align-items-center justify-content-center ${classNames && classNames.join(' ')}`; 
    return (
        <div className={className}>
            <AttackIcon /><span className='points mx-3'>{attack}</span>
            <DefenseIcon /><span className='points ms-3'>{defense}</span>
        </div>
    );
};

export default Traits;