import React, { useEffect, useMemo } from "react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.css";
import { ReactComponent as NextIcon} from "../../assets/img/next.svg"; 
import { ReactComponent as PrevIcon} from "../../assets/img/prev.svg"; 
import useWindowSize from "../../hooks/useWindowSize";

interface SliderType {
    items: any[];
    className?: string;
    perView: number;
    breakpoints: {[key: string]: number};
}

const Slider = ({items, className, perView, breakpoints }: SliderType) => {
    const classNames = ["container"];
    classNames.push(className || "");

    const _breakpoints: any = {};
    if (breakpoints) {
        Object.keys(breakpoints || {}).map((key: any)=> { 
            _breakpoints[key]={perView: breakpoints[key]};
        });
    }
  
    const slider: any = useMemo(()=> {
        const glide = new Glide(".glide", {
            type: "slider",
            rewind: false,
            perView: perView,
            breakpoints: _breakpoints
          });
          return glide;
    }, []);

    useEffect(() => {
        slider.mount();
        slider.go('<<');
    }, [items]);

    return <div className={classNames.join(" ")}>
        <div className="glide">
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                {
                    items.map((item, i)=><li key={i}>{item}</li>)
                } 
                </ul>
            </div>
            <div className="mt-5 ">
                <div  className="container d-flex justify-content-center align-items-center">
                    <a href="#" onClick={(e)=>{e.preventDefault(); slider.go('<');}} className="me-5">
                        <PrevIcon />
                    </a>
                    <a href="#" onClick={(e)=>{e.preventDefault();; slider.go('>');}} className="ms-5">
                        <NextIcon />
                    </a>
                </div>
            </div>
        </div>
    </div>;
};

const Grid = ({items}: any) => {
    return (
      <div className="container">
        <div className="row row-cols-auto justify-content-center">
        {
            items.map((item: any , i: number)=>
            <div key={i} className="col mt-3">{item}</div>
            )
        }
        </div>
      </div>
    );
};

export default ({items, className, perView, breakpoints }: SliderType) => {
    const windowSize = useWindowSize();
  
    function getViewPortSlides(width: number) {
        const [ breakpoint ] = Object.keys(breakpoints||{}).filter(b => Number(b)>width).sort((a,b)=> Number(a)-Number(b));
        return breakpoint? breakpoints[breakpoint]: perView;
    }

    const slidesByViewPort = getViewPortSlides(windowSize.width);

    if (items.length >= slidesByViewPort) {
        return <Slider items={items} className={className} perView={perView} breakpoints={breakpoints} />;
    } else {
        return <Grid items={items} />;
    }
};