import React, { useState } from "react";
import "./styles.scss";

interface TagsType {
    tags: {
        name: string;
        label: string;
        image: string;
    }[],
    onSelect?: any;
    className?: string;
    selected?: number;
}
const Tags = ({tags, onSelect, className, selected}: TagsType) => {
    const classNames = ["container", "tags"];
    classNames.push(className || "");

    const [selectedTagIndex, setSelectedTagIndex] = useState<number | undefined>(selected);

    const handleOnClickTag = (index: number, e: React.MouseEvent) => {
        e.preventDefault();

        const selectedNew = (index != selectedTagIndex)? index: undefined;
        setSelectedTagIndex(selectedNew);
        if (!!onSelect) {
            onSelect(selectedNew);
        }
    };

    return (
        <div className={classNames.join(" ")}>
            <div className="row d-flex justify-content-center">
                {
                    tags.map((tag, index) => 
                        <div key={index} className="col-auto text-center pb-md-2 px-0 mx-2 mx-md-4">
                            <p className="mb-1 mb-md-3">{tag.label}</p>
                            <div className={`pb-2 pb-md-3 ${(index == selectedTagIndex)? "selected": ""}`} style={{display: "inline-block"}}>
                                <a href="#" onClick={(e) => handleOnClickTag(index, e)}>
                                    <img src={tag.image} />
                                </a>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Tags;