import { ExtensionLoginButton } from "@multiversx/sdk-dapp/UI/extension/ExtensionLoginButton/ExtensionLoginButton";
import { LedgerLoginButton } from "@multiversx/sdk-dapp/UI/ledger/LedgerLoginButton/LedgerLoginButton";
import { WalletConnectLoginButton } from "@multiversx/sdk-dapp/UI/walletConnect/WalletConnectLoginButton/WalletConnectLoginButton";
import { WebWalletLoginButton } from "@multiversx/sdk-dapp/UI/webWallet/WebWalletLoginButton/WebWalletLoginButton";

import { routeNames } from "routes";
import { Modal } from "react-bootstrap";
import { walletConnectV2ProjectId } from "../../config";
import "./LoginModal.scss";

const LoginModal: React.FC<{
  show: any;
  handleClose: any;
}> = ({ show, handleClose }) => {
  const commonProps = {
    callbackRoute: routeNames.home,
    nativeAuth: true, // optional
  };

  return (
    <Modal
      dialogClassName="login-modal"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="border-0 text-white"
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Connect to wallet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="connect-buttons">
        <ExtensionLoginButton
          loginButtonText="MultiversX DeFi Wallet"
          {...commonProps}
          className="connect-buttons btn"
        >
          <span className="d-flex justify-content-center  align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 21"
              className="maiar-symbol me-3"
            >
              <g transform="translate(11024 -18800.5)">
                <path
                  className="cls-cyan"
                  d="M21.619-15H3.75A.75.75,0,0,1,3-15.75a.75.75,0,0,1,.75-.75h18a.75.75,0,0,0,.75-.75,2.25,2.25,0,0,0-2.25-2.25H3a3,3,0,0,0-3,3v15a3,3,0,0,0,3,3H21.619A2.321,2.321,0,0,0,24-.75v-12A2.321,2.321,0,0,0,21.619-15Z"
                  transform="translate(-11024 18820)"
                ></path>
                <g transform="translate(-11017.25 18810) scale(0.3)">
                  <path d="M17.8956 12.0018L32.0458 4.52327L29.6668 0L16.7098 5.18441C16.3761 5.31809 16.0062 5.31809 15.6726 5.18441L2.71192 0L0.333008 4.52327L14.4832 12.0018L0.333008 19.4767L2.71192 24L15.669 18.8156C16.0027 18.6819 16.3725 18.6819 16.7061 18.8156L29.6632 24L32.0421 19.4767L17.8919 11.9982L17.8956 12.0018Z"></path>
                </g>
                <circle
                  className="cls-black"
                  cx="1.314"
                  cy="1.314"
                  r="1.314"
                  transform="translate(-11004.771 18812.314)"
                ></circle>
              </g>
            </svg>
            MultiversX DeFi Wallet
          </span>
        </ExtensionLoginButton>
        <WalletConnectLoginButton
          {...commonProps}
          {...(walletConnectV2ProjectId
            ? {
                isWalletConnectV2: true,
              }
            : {})}
          className="connect-buttons btn"
        >
          <span className="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="21"
              viewBox="0 0 17.5 28"
              className="maiar-symbol maiar-app me-4"
            >
              <g transform="translate(11007 -18884.001)">
                <path
                  className="cls-cyan"
                  d="M14.875-21H2.625A2.626,2.626,0,0,0,0-18.375V4.375A2.626,2.626,0,0,0,2.625,7h12.25A2.626,2.626,0,0,0,17.5,4.375v-22.75A2.626,2.626,0,0,0,14.875-21Z"
                  transform="translate(-11007 18905)"
                ></path>
                <g transform="translate(-11003 18892.168) scale(0.285)">
                  <path d="M17.8956 12.0018L32.0458 4.52327L29.6668 0L16.7098 5.18441C16.3761 5.31809 16.0062 5.31809 15.6726 5.18441L2.71192 0L0.333008 4.52327L14.4832 12.0018L0.333008 19.4767L2.71192 24L15.669 18.8156C16.0027 18.6819 16.3725 18.6819 16.7061 18.8156L29.6632 24L32.0421 19.4767L17.8919 11.9982L17.8956 12.0018Z"></path>
                </g>
                <circle
                  className="cls-black"
                  cx="1.5"
                  cy="1.5"
                  r="1.5"
                  transform="translate(-10999.75 18906.498)"
                ></circle>
              </g>
            </svg>
            xPortal App
          </span>
        </WalletConnectLoginButton>
        <LedgerLoginButton callbackRoute="/" className="btn">
          <span className="d-flex justify-content-center align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="elrond-symbol body me-4"
            >
              <path
                d="M34.4876 0.0413742H15.7717V25.323H41.0412V6.58293C41.0412 2.9933 38.091 0.0413742 34.5081 0.0413742C34.5019 0.0413742 34.4937 0.0413742 34.4876 0.0413742V0.0413742Z"
                fill="#142533"
              ></path>
              <path
                d="M9.93712 0.0413742H6.71166C3.12874 0.0413742 0.178574 2.99536 0.178574 6.58293V9.81256H9.93712V0.0413742Z"
                fill="#142533"
              ></path>
              <path
                d="M0.178574 15.6547H9.93712V25.4259H0.178574V15.6547Z"
                fill="#142533"
              ></path>
              <path
                d="M31.3648 40.9569H34.5903C38.1753 40.9569 41.1234 38.0029 41.1234 34.4153C41.1234 34.4092 41.1234 34.401 41.1234 34.3948V31.268H31.3648V40.9569Z"
                fill="#142533"
              ></path>
              <path
                d="M15.7717 31.268H25.5302V41.0392H15.7717V31.268Z"
                fill="#142533"
              ></path>
              <path
                d="M0.178574 31.268V34.4976C0.178574 38.0852 3.12874 41.0392 6.71166 41.0392H9.93712V31.268H0.178574Z"
                fill="#142533"
              ></path>
            </svg>
            Ledger
          </span>
        </LedgerLoginButton>
        <WebWalletLoginButton callbackRoute={"/"} className="btn">
          <span className="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="24"
              viewBox="0 0 27 24"
              className="elrond-symbol body me-3"
            >
              <g transform="translate(11028 -19030)">
                <path
                  className="cls-cyan"
                  d="M24.75-21H2.25A2.251,2.251,0,0,0,0-18.75v13.5A2.251,2.251,0,0,0,2.25-3h22.5A2.251,2.251,0,0,0,27-5.25v-13.5A2.251,2.251,0,0,0,24.75-21ZM22.5,1.875A1.122,1.122,0,0,0,21.375.75H16.753l-.872-2.616a.56.56,0,0,0-.534-.384H11.658a.56.56,0,0,0-.534.384L10.252.75H5.625a1.125,1.125,0,0,0,0,2.25h15.75A1.122,1.122,0,0,0,22.5,1.875Z"
                  transform="translate(-11028 19051)"
                ></path>
                <g transform="translate(-11021 19034) scale(0.4)">
                  <path
                    className="reverse"
                    d="M17.8956 12.0018L32.0458 4.52327L29.6668 0L16.7098 5.18441C16.3761 5.31809 16.0062 5.31809 15.6726 5.18441L2.71192 0L0.333008 4.52327L14.4832 12.0018L0.333008 19.4767L2.71192 24L15.669 18.8156C16.0027 18.6819 16.3725 18.6819 16.7061 18.8156L29.6632 24L32.0421 19.4767L17.8919 11.9982L17.8956 12.0018Z"
                  ></path>
                </g>
              </g>
            </svg>
            MultiversX Web Wallet
          </span>
        </WebWalletLoginButton>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
